import React, { useEffect, useContext, useRef, useState } from 'react';

import { data_live } from '../DataAPI/datalive'


import { global } from '../DataAPI/global'

import { MeuContexto } from "../App"

//import QRCode from "react-qr-code";

import Hls from "hls.js";
import './CompVideo.css'

let vrplayer = null

let hls = null




function Video({ lurl, lid, contar, legendas }) {



  const [hlsInstance, setHlsInstance] = useState(null);
  const [qualityLevels, setQualityLevels] = useState([]);
  const [selectedQuality, setSelectedQuality] = useState(-1); // -1 for auto

  useEffect(() => {
    _CarregarTela();





    try {
      if (contar) data_live.liveentrar(lid);
    } catch (error) {

    }
    return () => {
      try {
        if (contar) data_live.livesair(lid);
      } catch (error) {

      }

      try {
        hls.destroy();
      } catch (error) {

      }

    }
  }, [])



  function _CarregarTela() {
    if (hls) {
      hls.destroy()

    }

    var video = vrplayer

    var url = lurl


    var urllegenda = url
    /// console.log(lurl)
    var videoSrc = url  ///url/`${url}?sv=2019-12-12&ss=b&srt=sco&sp=rl&se=2022-11-20T05:21:45Z&st=2020-11-19T21:21:45Z&spr=https&sig=vxpoAvnryz9eguUurvrF%2F5IWP88lUnAl3f1AtwoMFkE%3D`   ///'https://judahtv.blob.core.windows.net/blob/5f85ced340ddd651ba8fa7d9/index.m3u8'
    if (url.length > 0) {
      if (url.substring(url.length - 5, url.length) === '.m3u8') {


        ////   console.log('teste')

        if (Hls.isSupported()) {

          var config = {};

          if (global.token) {

            config = {

              autoStartLoad: true,
              startPosition: -1,
              enableWorker: true,

              liveBackBufferLength: 4000,
              liveSyncDurationCount: 10,
              xhrSetup: (xhr, url) => {
                xhr.setRequestHeader('Authorization', `Bearer ${global.token}`);
              }


            };

            videoSrc = videoSrc.replace('/rec/', '/api/stream/')
     

          } else {





            config = {

              autoStartLoad: true,
              startPosition: -1,
              enableWorker: true,

              liveBackBufferLength: 4000,
              liveSyncDurationCount: 10,


            };


          }


          ;






          hls = new Hls(config);

          // console.log(url, config)

          hls.attachMedia(video);

          legendas.forEach((legenda) => {
            const track = document.createElement('track');
            track.kind = 'subtitles';
            track.label = legenda.label;
            track.srclang = legenda.lang;
            track.src = urllegenda.replace('/index.m3u8', `/${legenda.src}`);
            track.crossOrigin = 'anonymous';
        
            if (legenda.default) {
              track.default = true;
            }
            video.appendChild(track);
          })
          hls.on(Hls.Events.MEDIA_ATTACHED, function () {
            //   console.log("video and hls.js are now bound together !");
            hls.loadSource(videoSrc);

            hls.attachMedia(vrplayer.current);

            hls.on(Hls.Events.MANIFEST_PARSED, () => {
              setQualityLevels(hls.levels);
              setHlsInstance(hls);
              vrplayer.current.play();
            });

            hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
              //   console.log("manifest loaded, found " + data.levels.length + " quality level");

              video.play();
            });

          });

        }


        else if (video.canPlayType('application/vnd.apple.mpegurl')) {

          video.src = videoSrc;

          video.addEventListener('loadedmetadata', function () {
            video.play();

          });
        }
      } else {


        ////  console.log('teste2')
        video.src = videoSrc;
        video.addEventListener('loadedmetadata', function () {
          video.play();

        });

        legendas.forEach((legenda) => {
          const track = document.createElement('track');
          track.kind = 'subtitles';
          track.label = legenda.label;
          track.srclang = legenda.lang;
          track.src = urllegenda.replace('/index.m3u8', `/${legenda.src}`);
          track.crossOrigin = 'anonymous';
       
          if (legenda.default) {
            track.default = true;
          }
          video.appendChild(track);
        })


      }
    }
  }

  const handleQualityChange = (event) => {
    const level = parseInt(event.target.value, 10);
    setSelectedQuality(level);
    if (hlsInstance) {
      hlsInstance.currentLevel = level;
    }
  };

  return (<div>
    <video
      crossOrigin="anonymous"
      webkit-playsinline="true"
      playsInline
      controls
      controlsList="nodownload"
      className="lvideoplay"
      ref={(player) => (vrplayer = player)}
      autoPlay={true}
      loop
    />
    {qualityLevels.length > 0 && (
      <div>
        <label htmlFor="qualitySelect">Quality: </label>
        <select id="qualitySelect" value={selectedQuality} onChange={handleQualityChange}>
          <option value="-1">Auto</option>
          {qualityLevels.map((level, index) => (
            <option key={index} value={index}>
              {level.height}p
            </option>
          ))}
        </select>
      </div>
    )}

    {/*  <div style={{ bottom: 60, left: 60, padding: 10, backgroundColor: 'white', position: 'absolute' }}>
      <p>PIX</p>
      <QRCode value={perfil.pixchave} level="L" size="120" />
    </div>
*/}
  </div>);
}

export default Video;
