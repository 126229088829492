 

export const validar = {

    _email(email) {

        let usuario = email.substring(0, email.indexOf("@"));
        let dominio = email.substring(email.indexOf("@") + 1, email.length);

        if ((usuario.length >= 1) &&
            (dominio.length >= 3) &&
            (usuario.search("@") === -1) &&
            (dominio.search("@") === -1) &&
            (usuario.search(" ") === -1) &&
            (dominio.search(" ") === -1) &&
            (dominio.search(".") !== -1) &&
            (dominio.indexOf(".") >= 1) &&
            (dominio.lastIndexOf(".") < dominio.length - 1)) {
            return { valido: true, email: email }
        }

        return { valido: false, email: email }
    },

    _senha(campo) {

        return { valido: false, campo: campo }
    },

    _cpf(cpf) {

        let add
        let i
        let rev

        cpf = cpf.replace(/[^\d]+/g, '');
        if (cpf === '') return { valido: false, cpf: cpf }
        // Elimina CPFs invalidos conhecidos	
        if (cpf.length !== 11 ||
            cpf === "00000000000" ||
            cpf === "11111111111" ||
            cpf === "22222222222" ||
            cpf === "33333333333" ||
            cpf === "44444444444" ||
            cpf === "55555555555" ||
            cpf === "66666666666" ||
            cpf === "77777777777" ||
            cpf === "88888888888" ||
            cpf === "99999999999")
            return { valido: false, cpf: cpf }
        // Valida 1o digito	
        add = 0;
        for (i = 0; i < 9; i++)
            add += parseInt(cpf.charAt(i)) * (10 - i);
        rev = 11 - (add % 11);
        if (rev === 10 || rev === 11)
            rev = 0;
        if (rev !== parseInt(cpf.charAt(9)))
            return { valido: false, cpf: cpf }
        // Valida 2o digito	
        add = 0;
        for (i = 0; i < 10; i++)
            add += parseInt(cpf.charAt(i)) * (11 - i);
        rev = 11 - (add % 11);
        if (rev === 10 || rev === 11)
            rev = 0;
        if (rev !== parseInt(cpf.charAt(10)))
            return { valido: false, cpf: cpf }
        return { valido: true, cpf: cpf }
    },


    _cartao(cartaonr) {

        var cartoes = {
            visa: /^4[0-9]{12}(?:[0-9]{3})/,
            mastercard: /^5[1-5][0-9]{14}/,
            diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
            amex: /^3[47][0-9]{13}/,
            discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
            hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
            elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
            jcb: /^(?:2131|1800|35\d{3})\d{11}/,
            aura: /^(5078\d{2})(\d{2})(\d{11})$/
        };

        for (var cartao in cartoes) if (cartaonr.match(cartoes[cartao])) return { valido: true, cartao: cartao }
        return { valido: false, cartao: cartao }

    },

    _cartaodata(dataentrada) {


        var patternData = /^[0-9]{2}\/[0-9]{4}$/;
        if (patternData.test(dataentrada))

            return { valido: true, vencimento: dataentrada }

        return { valido: false, vencimento: dataentrada }

    }
    ,
    _cartaocvv(cvv) {
        var cartoes = {
            visa: /^[0-9]{3}/,
            mastercard: /^[0-9]{4}/,
        }
        for (var cartao in cartoes) if (cvv.match(cartoes[cartao])) return { valido: true, cvv: cvv }
        return { valido: false, cvv: cvv }

    },

    _nome(nome) {

        return { valido: false, nome: nome }
    },


    _requerido(campo) {
        if (campo.length > 0) {

            return { valido: true, campo: campo }
        }
        return { valido: false, campo: campo }
    },

    _limparcamponr(campo) {


        return '0'
    },

    _limparcampotexto(campo) {

        return ''
    },


  

}
