import React, { useState } from "react"
import './App.css';
import Rotas from './Rotas'



export const MeuContexto = React.createContext()

function App() {


 
  const [logado, setlogado] = useState(false)
  const [username, setusername] = useState('')
  const [gett, setgett] = useState(false)

  const [perfil, setperfil] = useState([])

  return (
    <MeuContexto.Provider value={{  logado, setlogado, username, setusername, gett, setgett ,perfil, setperfil}} >
      <div>


        <Rotas />
      </div>
    </MeuContexto.Provider>

  );
}

export default App;
