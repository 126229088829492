import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from "react-router-dom";

import './PgUsuario.css';

import { MeuContexto } from "../App"

import { data_user } from '../DataAPI/datauser'
import { validarME } from '../DataAPI/apiMe'

import { validar } from '../DataAPI/apiValidar'

import CompActive from '../Componentes/CompActive'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { global } from '../DataAPI/global'
import calcMD5 from '../Componentes/md5'

import personagem from '../img/person.png'

function PgUsuario() {
    let history = useHistory();

    const { logado, setlogado } = useContext(MeuContexto)

    const [isloading, setisloading] = useState(true);

    const [avatarSource, setavatarSource] = useState('');


    const [userid, setuserid] = useState(0)
    const [username, setusername] = useState('')
    const [email, setemail] = useState('')
    const [comment, setcomment] = useState('')
    const [artist, setartist] = useState(false)
    const [estilo, setestilo] = useState('')
    const [cpf, setcpf] = useState('')
    const [title, settitle] = useState('')

    const [paga, setpaga] = useState(false)
    const [preco, setpreco] = useState(10)
    ///const [proximalive, setproximalive] = useState(new Date());
    const [wifi, setwifi] = useState(false)

    const [vusername, setvusername] = useState(true)
    const [vemail, setvemail] = useState(true)
    const [vcpf, setvcpf] = useState(true)


    useEffect(
        () => {
            _carregatela()
        }, [])

    useEffect(
        () => {

            if (!logado) { history.push("/") }
        }, [logado])


    async function _carregatela() {
        const me = await validarME.me()
        if (me) {

            setuserid(me._id)
            setusername(me.username)
            setemail(me.email)
            setcpf(me.cpf)
            setcomment(me.comment)
            setartist(me.artist)
            setisloading(false)

            if (me.fotoid !== '') {
                setavatarSource(`${global.brl.REACT_APP_FOTO}${me.fotoid}.jpg`)
            }

        } else {

            setisloading(false)
            setlogado(false)
        }
    }


    function _validar() {


        let retorno = true

        if (!validar._email(email).valido) { setvemail(false); retorno = false } else { setvemail(true) }
        if (!validar._requerido(email).valido) { setvemail(false); retorno = false } else { setvemail(true) }
        if (!validar._requerido(username).valido) { setvusername(false); retorno = false } else { setvusername(true) }

        if (artist) {

            if (!validar._cpf(cpf).valido) {
                toast.warn(

                    'Atenção! É necessário colocar um CPF válido !'
                );
                setvcpf(false)
                return false
            } else { setvcpf(true) }

        }
        return retorno
    }

    async function _salvar(e) {
        e.preventDefault();
        if (_validar()) {


            const data = {

                id: userid,
                username,
                email,
                cpf,
                comment,
                artist,
                paga,
                preco,
                estilo, title, wifi,
            }

            const resposta = await data_user.atualizar(data);
            if (resposta) {
                setisloading(false)
                toast.success(

                    'Sua conta foi atualizada!'
                );

            } else {

                setisloading(false)
                toast.error(

                    'Não conseguimos atualizar a sua conta!'
                );

            }


        }

        else {
            setisloading(false)
            toast.warn(

                'Favor verificar suas informações!'
            );

        }
    }


    function _transmissao() {


        var chavetransmissao = ''


        var tempuser = userid
        ////  tempuser = '5ec537286af036023f0f5fcf'

        var now = new Date();
        var expiryDate = new Date(now.getTime() + 1000 * 60 * 60 * 24 * 10);


        /// 1000*60*60*24*30
        var securl = `/live/${tempuser}-${expiryDate.getTime()}-210976`

        ///  'rtmp://example.com/live/stream?sign=1575453962-22d7d03aa3fe7d880f29bed08ccb25f0
        var xvurl = `${tempuser}?sign=${expiryDate.getTime()}-${calcMD5(securl)}`

        //  console.log(xvurl)
        chavetransmissao = xvurl



        var textField = document.createElement('textarea')
        textField.innerText = chavetransmissao
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()




        toast.info(

            `Sua chave de transmissão (${chavetransmissao}) foi copiada`
        );

    }


    return (
        <div>
            <ToastContainer position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover={false} />
            {(isloading) ? (<><CompActive /></>) : (
                <div className={"ulabel1"}>



                    <form className={"uform"} onSubmit={(e) => { setisloading(true); _salvar(e) }} autoComplete="off">

                        <h2 className={"ulabel1"}>Configuração da Conta</h2>

                        <img className={"cfotouser"} src={avatarSource} onError={(e) => { e.target.onerror = null; e.target.src = personagem }} alt="foto" width="100" height="100" />




                        {/* <label for="img">Select image: </label>
                    <br></br>
                    <input type="file" id="img" name="img" accept="image/*"
                    value={avatarSource}
                    onChange={e => setavatarSource(e.target.files[0])} />
            <br></br> */}

                        <label className={"ulabel"} for="username">Nome de exibição:<span className={"uspan"}>*</span></label>
                        <input
                            className={"uinput"}
                            autoComplete="off"
                            placeholder="Nome"
                            name="username"
                            type="text"
                            value={username}
                            onChange={e => setusername(e.target.value)} />
                        {(!vusername) ? (<><p className={"uspan ualinha"} for="username">Verificar Nome</p></>) : (<></>)}


                        <label className={"ulabel"} for="email">Email:<span className={"uspan"}>*</span></label>
                        <input
                            className={"uinput"}
                            autoComplete="off"
                            placeholder="seu@email.com.br"
                            name="email"
                            type="email"
                            value={email}
                            onChange={e => setemail(e.target.value)} />
                        {(!vemail) ? (<><p className={"uspan ualinha"} for="email">Verificar Email</p></>) : (<></>)}



                        <label className={"ulabel"} for="comment">Descreva seu perfil:</label>
                        <textarea
                            className={"uinput"}
                            rows="10" cols="30"
                            autoComplete="off"
                            placeholder="Olá, seja bem vindo ao meu perfil!"
                            name="comment"
                            type="text"
                            value={comment}
                            onChange={e => setcomment(e.target.value)} />


                        <div className="udiv2">
                            <input className={(!artist) ? "ubtinput BtAtivo" : "ubtinput BtInativo"} type="button" onClick={() => { setartist(false) }} value="Público" />
                            <input className={(artist) ? "ubtinput BtAtivo" : "ubtinput BtInativo"} type="button" onClick={() => { setartist(true) }} value="Artista" />

                        </div>


                        <label className={"ulabel"} for="estilo">Categoria:</label>
                        <select

                            className={"uinput"}
                            id="estilo"
                            name="estilo"
                            value={estilo}
                            onChange={e => setestilo(e.target.value)}

                        >
                            <option value="Musica">Musica</option>
                            <option value="Teatro">Teatro</option>


                        </select>


                        {

                            ///se for artista
                            (artist) ? (<>

                                <label className={"ulabel"} for="cpf">CPF:<span className={"uspan"}>*</span></label>
                                <input
                                    className={"uinput"}
                                    autoComplete="off"
                                    placeholder="CPF"
                                    name="cpf"
                                    type="text"
                                    value={cpf}
                                    onChange={e => setcpf(e.target.value)} />
                                {(!vcpf) ? (<><p className={"uspan ualinha"} for="cpf">Verificar CPF</p></>) : (<></>)}

                                <label className={"ulabel"} for="title">Titulo da Live:</label>
                                <input
                                    className={"uinput"}
                                    autoComplete="off"
                                    placeholder="Prévia da live (máximo 32 caracteres)"
                                    name="title"
                                    type="text"
                                    value={title}
                                    onChange={e => settitle(e.target.value)} />



                                <div className="udiv2">
                                    <input className={(paga) ? "ubtinput BtAtivo Btvermelho" : "ubtinput BtInativo"} type="button" onClick={() => { setpaga(true) }} value="PAGA" />
                                    <input className={(!paga) ? "ubtinput BtAtivo Btverde" : "ubtinput BtInativo"} type="button" onClick={() => { setpaga(false) }} value="FREE" />

                                </div>




                                {
                                    ///se live esta paga
                                    (paga) ? (<>

                                        <label className={"ulabel4"} for="preco">Valor da Live: <span className="lbamarelo"> {preco} reais </span> </label>


                                        <div className="udiv2">

                                            <input className={"ubtinput BtInativo"} type="button" onClick={() => { setpreco(preco + 10) }} value="+ 10 reais" />
                                            <input className={"ubtinput BtInativo"} type="button" onClick={() => { (preco < 20) ? setpreco(10) : setpreco(preco - 10) }} value="- 10 reais" />


                                        </div>

                                    </>) : (<></>)}

                                {/* 
                                <label className={"ulabel"} for="proximalive">Próxima Live:</label>
                                <input
                                    className={"uinput"}
                                    name="proximalive"
                                    type="datetime-local"
                                    value={proximalive}
                                    onChange={e => setproximalive(e.target.value)}
                                    min={Date.now} max={Date.now + 8} />
*/}

                                <label className={"ulabel"} for="wifi">Transmissão:</label>

                                <div className="udiv2">

                                    <input className={(!wifi) ? "ubtinput BtAtivo Btamarelo" : "ubtinput BtInativo"} type="button" onClick={() => { setwifi(false) }} value="HD" />
                                    <input className={(wifi) ? "ubtinput BtAtivo" : "ubtinput BtInativo"} type="button" onClick={() => { setwifi(true) }} value="FULL HD" />
                                </div>


                            </>) : (<></>)}











                        <br></br>





                        <br></br>

                        {(artist) ? (<>

                            {/*   <div className="btsepara">


                                <button className="btcancelar" type="button" onClick={() => { _transmissao() }} value="Chave">Chave Transmissão</button>
                            </div> */} </>) : (<></>)}



                        <br></br>
                        <div className="btsepara">



                            <button className="btsalvar" type="submit" value="Submit">Salvar</button>

                            <button className="btcancelar" type="button" onClick={() => { setisloading(true); _carregatela() }} value="Cancelar">Cancelar</button>
                        </div>
                    </form>





                </div>)}
        </div>
    );
}

export default PgUsuario;