import React, { useEffect, useContext, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";

import './PgCanal.css';

import { MeuContexto } from "../App"
import { validarME } from '../DataAPI/apiMe'
import { data_user } from '../DataAPI/datauser'
import CompActive from '../Componentes/CompActive'
import Video from '../Componentes/CompVideo'
import Chat from '../Componentes/CompChat'

import { MdFavoriteBorder, MdShare } from "react-icons/md";
import {
    parseISO,
    formatDistance, isAfter,
} from 'date-fns';

import { ptBR } from 'date-fns/locale';

import personagem from '../img/person.png'

import { data_fatlives } from '../DataAPI/datafatlives'



import ModalCard from '../Componentes/CompModal'

import ModalCardIugu from '../Componentes/CompModalIugu'

//import Iugu from './PgPix'

import Iugu from './PgCard'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



import { global } from '../DataAPI/global'

let vrcount = 0
let vruserid = ''

function PgCanal() {
    let { liveId } = useParams();
    let history = useHistory();

    const [contarlive, setcontarlive] = useState(false)
    const [showmodal, setshowmodal] = useState(false);

    const [showmodalIugu, setshowmodalIugu] = useState(false);


    const [ativo, setativo] = useState(1)

    const { logado, setlogado, setusername, perfil, setperfil } = useContext(MeuContexto)

    const [isloading, setisloading] = useState(true);

    const [user, setuser] = useState([])
    const [entrar, setentrar] = useState(false)

    const [pago, setpago] = useState(false)

    const [urlvideo, seturlvideo] = useState('')

    const [count, setcount] = useState(0)

    const [legendas, setlegendas] = useState([])




    useEffect(
        () => {



            _carregatela()

        }, []
    )
    async function _carregatela() {
        const me = await validarME.me()
        if (me) {

            vruserid = me._id

            setcontarlive(false)
            setuser(me)

            setusername(me.username)

            setlogado(true)

        } else {
            setcontarlive(true)
            setlogado(false)
            // history.push("/") 
        }
        Atualizar()
    }
    async function Atualizar() {

        const resposta = await data_user.telalink(liveId)


        if (resposta) {
            //  console.log(resposta)

            setperfil(resposta)

            if (resposta.legenda1) {
                setlegendas([
                    { src: resposta.legenda3, lang: 'es', label: 'Spanish' },
                    { src: resposta.legenda2, lang: 'en', label: 'English' },
                    { src: resposta.legenda1, lang: 'br', label: 'Portugues', default: true },
                ]);
                    
            };
            
            if (resposta.online) {
                seturlvideo(`${global.brl.REACT_APP_EVIPLIVE1ini}${resposta._id}${global.brl.REACT_APP_EVIPLIVE1fim}`)

                if (resposta.paga) {
                    if (logado) {

                        const respostapgto = await data_fatlives.extratoPago(resposta._id, vruserid)

                        if (respostapgto.pago) {
                            setpago(true)

                            //    setshowmodal(true)

                        } else {
                            setpago(false)
                        }
                    } else {
                        setpago(false)
                    }
                } else {


                    //    setshowmodal(true)

                }

            } else {

                if (resposta.mostrar) {
                    if (resposta.linkultimo !== '') {
                        seturlvideo(`${global.brl.REACT_APP_EVIPPLAY1ini}${resposta._id}/${resposta.linkultimo}`)
                        //  console.log(`${global.brl.REACT_APP_EVIPPLAY1ini}${resposta._id}/${resposta.linkultimo}`)
                    }
                }
                if (resposta.linkplay !== '') {
                    seturlvideo(`${global.brl.REACT_APP_EVIPPLAY2ini}${resposta.linkplay}`)
                    //  console.log(`${global.brl.REACT_APP_EVIPPLAY2ini}${resposta.linkplay}`)
                }


                if (resposta.paga) {

                    if (logado) {


                        const respostapgto = await data_fatlives.extratoPago(resposta._id, vruserid)




                        if (respostapgto.pago) {
                            //  console.log('pago')

                            setpago(true)

                            //  setshowmodal(true)

                        } else {
                            //  console.log('nao pago')
                            // setpago(false)
                            setpago(false)

                        }


                    } else {
                        // setpago(false)

                        //   console.log('erro')
                        setpago(false)
                    }
                } else {


                    //   setshowmodal(true)
                    //   console.log('nao sei')


                }
            }

        } else {
            resposta([])
        }
        setisloading(false)
    }

    function copyToClipboard(e) {


        var textField = document.createElement('textarea')
        textField.innerText = `https://smize.tv/Canal/${liveId}`
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()




        toast.info(

            'Link Copiado para o compartilhamento!'
        );

    };

    return (
        <div>
            <ToastContainer position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover={false} />

            {(isloading) ? (<><CompActive /></>) : (

                <div className="cconteudo">


                    <div className="cflr">

                        <div >
                            <img className="cfoto" src={`${global.brl.REACT_APP_FOTO}${perfil.fotoid}.jpg`} onError={(e) => { e.target.onerror = null; e.target.src = personagem }} alt="foto" />
                        </div>

                        <div className="cflc cmarginl">
                            <h2 className="cluser">{perfil.username}</h2>
                            <h4 className="clestilo">{perfil.estilo}</h4>

                        </div>

                    </div>

                    <div className="cbaixo">

                        <div className="cflr2">
                            <div className="cflr2a">
                                <MdFavoriteBorder />
                                <label className="cmarginl">{perfil.seguidores}</label>
                            </div>
                            <div className="cflr2b">
                                <MdShare onClick={(e) => { copyToClipboard(e) }} />
                            </div>
                        </div>

                        <div className="cflr cmargint cflre">

                            <div className="cflc">
                                <label className="cmargint clestilo" >Próxima Live:</label>
                                <div className="cmarginl cmargint">
                                    <label className="cmargint" >{perfil.title}</label>
                                    <div className="cmargint">
                                        {(perfil.proximalive) ? (
                                            (isAfter(parseISO(perfil.proximalive), new Date()) ? (
                                                <label >{formatDistance(parseISO(perfil.proximalive), new Date(), { locale: ptBR, addSuffix: false, includeSeconds: false }).replace("cerca de ", "")}</label>
                                            ) : <>
                                            </>)) : (<>
                                            </>)}
                                    </div>
                                </div>
                            </div>
                            <div className="cflc cmarginr">


                                {(perfil.paga) ? (
                                    <>

                                        {(pago) ? (<>


                                            {(urlvideo !== '') ? (<> <button className="btvideo tbazul" onClick={() => { setshowmodal(true) }}>ENTRAR</button></>) : (<></>)}



                                        </>) :
                                            (<><button className="btvideo tbvermelho" onClick={() => { setshowmodalIugu(true) }}>COMPRAR</button>
                                            </>)}
                                    </>) :
                                    (<>
                                        <button className="btvideo tbverde" onClick={() => { setshowmodalIugu(true) }}>CONTRIBUIR</button>

                                        {(urlvideo !== '') ? (<><button className="btvideo tbazul" onClick={() => { setshowmodal(true) }}>ENTRAR</button></>) : (<></>)}


                                    </>)}









                            </div>

                        </div>
                    </div>

                    <div className="cmargint cmarginb">
                        <br></br>
                        <label className="cmargint clestilo" >Descrição:</label>
                        <p className="clp ">{perfil.comment}</p>
                        <br></br>
                        <br></br>
                    </div>

                    {(showmodal) ? (<>

                        <ModalCard show={showmodal} close={() => {

                            setshowmodal(false)
                        }} title={`${perfil.username} - ${perfil.title}`} >
                            <div className="mdct2">

                                <div className={(user._id) ? "mdct" : "mdct88"}>
                                    <div className="item1" >

                                        {(urlvideo !== '') ? (<> <Video lurl={urlvideo} lid={perfil._id} contar={contarlive} legendas={legendas} /></>) : (<></>)}
                                    </div>

                                    {(user._id) ? (<>

                                        <div className="item2" >


                                            <Chat ativo={ativo} seArtista={false} onPress={() => { vrcount++; setcount(vrcount) }} onDenunciar={() => { vrcount++; setcount(vrcount) }} user={user} room={perfil._id} />

                                        </div>

                                    </>) : (<></>)}

                                </div>

                            </div>







                        </ModalCard>
                    </>) : (<></>)}






                    {(showmodalIugu) ? (<>

                        <ModalCardIugu show={showmodalIugu} close={() => {

                            setshowmodalIugu(false)
                        }} title={(perfil.paga) ? "INGRESSO" : "CONTRIBUIR"} >
                            <div className="mdct2">

                                <div className={"mdct89"}>
                                    <div className="item4" >

                                        <Iugu artista={perfil} publico={user} close={() => { setshowmodalIugu(false) }} />
                                    </div>



                                </div>

                            </div>







                        </ModalCardIugu>
                    </>) : (<></>)}




                </div>)}


        </div>

    );
}

export default PgCanal;