
import { data_user } from './datauser'

export const validarME = {


    async me() {
        const respostame = await data_user.me();
        if (respostame) {

            
            if (respostame.banido) { return null } else { return respostame }
        }
        return null

    }
}
 