import React from 'react';

// import { Container } from './styles';
import "./CompChatItem.css"

import personagem from '../img/person.png'

import { global } from '../DataAPI/global'

function CompChatItem({ item }) {
  return (

    <div className="message">
      <div className="cilinha1">
        <img className="avatar" src={`${global.brl.REACT_APP_FOTO}${item.foto}.jpg`} onError={(e) => { e.target.onerror = null; e.target.src = personagem }} alt="foto" width="42" height="42" />
        <div>
          <p className="citexto" for="username">{item.username}</p>

          <p className="citexto" for="menssagem">{item.chatMessage}</p>
        </div>
      </div>
    </div>

  );
}

export default CompChatItem;