import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from "react-router-dom"

import './PgSignUp.css';


import { MeuContexto } from "../App"
import { global } from '../DataAPI/global'
import { data_user } from '../DataAPI/datauser'
import { validar } from '../DataAPI/apiValidar'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function PgSignUp() {





    let history = useHistory();

    

    const { logado, setlogado } = useContext(MeuContexto)



    const [username, setusername] = useState('')
    const [password, setpassword] = useState('')

    const [email, setemail] = useState('')
    const [cpf, setcpf] = useState('')
    const [artist, setartist] = useState(false)

    const [vusername, setvusername] = useState(true)
    const [vemail, setvemail] = useState(true)
    const [vpassword, setvpassword] = useState(true)
    const [vcpf, setvcpf] = useState(true)

    useEffect(
        () => {

            if (logado) { history.push("/") }
        }, [logado])


    function _validar() {


        let retorno = true

        if (!validar._email(email).valido) { setvemail(false); retorno = false } else { setvemail(true) }
        if (!validar._requerido(email).valido) { setvemail(false); retorno = false } else { setvemail(true) }
        if (!validar._requerido(password).valido) { setvpassword(false); retorno = false } else { setvpassword(true) }

        if (!validar._requerido(username).valido) { setvusername(false); retorno = false } else { setvusername(true) }

        if (artist) {

            if (!validar._cpf(cpf).valido) {
                toast.warn(

                    'Atenção! É necessário colocar um CPF válido!'
                );
                setvcpf(false)
                return false
            } else { setvcpf(true) }

        }
        return retorno
    }


    async function _Criarconta(e) {
        e.preventDefault();
        if (_validar()) {
            try {
                const resposta = await data_user.novo({ username, email, password, artist, cpf });
                if (resposta) {
                    const resposta2 = await data_user.conectar({ email, password });
                    if (resposta2) {
                        global.token = resposta2.token

                        setlogado(true)
                        history.push("/")

                    }

                }

            } catch (error) {
                toast.error(

                    'Favor verificar suas informações!'
                );
            }


        }
        else {
            toast.warn(

                'Favor verificar suas informações!'
            );

        }

    }


    return (
        <div className={"ulabel1"}>
            <ToastContainer position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover={false} />

            <form className={"uform"} onSubmit={_Criarconta} autoComplete="off">
                <h2 className={"ulabel1"}>Bem vindo! Crie sua conta!</h2>

                <label className={"ulabel"} for="username">Nome de exibição:<span className={"uspan"}>*</span></label>
                <input
                    className={"uinput"}
                    autoComplete="off"
                    placeholder="Nome"
                    name="username"
                    type="text"
                    value={username}
                    onChange={e => setusername(e.target.value)} />
                {(!vusername) ? (<><p className={"uspan ualinha"} for="username">Verificar Nome</p></>) : (<></>)}


                <label className={"ulabel"} for="email">Email:<span className={"uspan"}>*</span></label>
                <input
                    className={"uinput"}
                    autoComplete="off"
                    placeholder="seu@email.com.br"
                    name="email"
                    type="email"
                    value={email}
                    onChange={e => setemail(e.target.value)} />
                {(!vemail) ? (<><p className={"uspan ualinha"} for="email">Verificar Email</p></>) : (<></>)}


                <label className={"ulabel"} for="password">Senha:<span className={"uspan"}>*</span></label>
                <input
                    className={"uinput"}
                    autoComplete="off"
                    placeholder="******"
                    name="password"
                    type="password"
                    value={password}
                    onChange={e => setpassword(e.target.value)} />
                {(!vpassword) ? (<><p className={"uspan ualinha"} for="password">Verificar Senha</p></>) : (<></>)}
 
                <div className="udiv2">
                    <input className={(!artist) ? "ubtinput BtAtivo" : "ubtinput BtInativo"} type="button" onClick={() => { setartist(false) }} value="Público" />
                    <input className={(artist) ? "ubtinput BtAtivo" : "ubtinput BtInativo"} type="button" onClick={() => { setartist(true) }} value="Artista" />

                </div>

                {(artist) ? (<>
                    <label className={"ulabel"} for="cpf">CPF:<span className={"uspan"}>*</span></label>
                    <input
                        className={"uinput"}
                        autoComplete="off"
                        placeholder="CPF"
                        name="cpf"
                        type="text"
                        value={cpf}
                        onChange={e => setcpf(e.target.value)} />
                    {(!vcpf) ? (<><p className={"uspan ualinha"} for="cpf">Verificar CPF</p></>) : (<></>)}


                </>) : (<></>)}
 
                <br></br>

                <button className="btsalvar" ype="submit" value="Submit">Criar conta</button>

                <button className="btcancelar" type="button" onClick={() => { history.push("/") }} value="sair">Cancelar</button>
            </form>

        </div>

    );
}

export default PgSignUp;